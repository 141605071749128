import * as Auth from "../Constants/Auth-Constants"

export const LoginReducer = (state = {}, action) => {
  switch (action.type) {
    case Auth.LOGIN_REQUEST:
      return { loading: true };

    case Auth.LOGIN_SUCCESS:
      return {
        loading: false,
        success: true,
        userData: action.payload,
        message: action.payload.message,
      };

    case Auth.LOGIN_FAIL:
      return { loading: false, error: action.payload };

    case Auth.USER_LOGOUT:
      return {};

    default:
      return state;
  }
};

export const RegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case Auth.REGISTER_REQUEST:
      return { loading: true };

    case Auth.REGISTER_SUCCESS:
      return {
        loading: false,
        success: true,
        userData: action.payload,
        message: action.payload.message,
      };

    case Auth.REGISTER_FAIL:
      return { loading: false, error: action.payload };

    case Auth.USER_LOGOUT:
      return {};

    default:
      return state;
  }
};

export const VerifyEmailReducer = (state = {}, action) => {
  switch (action.type) {
    case Auth.VERIFY_EMAIL_REQUEST:
      return { loading: true };

    case Auth.VERIFY_EMAIL_SUCCESS:
      return {
        loading: false,
        message: action.payload.message,
        success: true,
      };
    case Auth.VERIFY_EMAIL_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const ForgotPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case Auth.FORGOT_PASSWORD_REQUEST:
      return { loading: true };

    case Auth.FORGOT_PASSWORD_SUCCESS:
      return {
        loading: false,
        message: action.payload.message,
        success: true,
      };

    case Auth.FORGOT_PASSWORD_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};


export const UpdatePasswordreducer = (state = {}, action) => {
  switch (action.type) {
    case Auth.UPDATE_PASSWORD_REQUEST:
      return { loading: true };

    case Auth.UPDATE_PASSWORD_SUCCESS:
      return {
        loading: false,
        success: true,
        userData: action.payload,
        message: action.payload.message,
      };

    case Auth.UPDATE_PASSWORD_FAIL:
      return { loading: false, error: action.payload };

    case Auth.USER_LOGOUT:
      return {};

    default:
      return state;
  }
};



export const ProfileUpadteReducer = (state = { ProfileUpadte: [] }, action) => {
  switch (action.type) {
    case Auth.PROFILE_UPADTE_REQUEST:
      return { loading: true };
    case Auth.PROFILE_UPADTE_SUCCESS:
      return {
        loading: false,
        success: true,
        ProfileUpadte: action.payload,
        message: action.payload.message,
      };

    case Auth.PROFILE_UPADTE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};



export const ProfileDetailsGetReducer = (state = { ProfileUpadteDataGet: [] }, action) => {
  switch (action.type) {
    case Auth.PROFILE_DETAILS_GET_REQUEST:
      return { loading: true };
    case Auth.PROFILE_DETAILS_GET_SUCCESS:
      return {
        loading: false,
        success: true,
        ProfileUpadteDataGet: action.payload,
        message: action.payload.message,
      };

    case Auth.PROFILE_DETAILS_GET_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};