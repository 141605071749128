import React from "react";
import "./Spinner.css";
import { CSpinner } from "@coreui/react";

export default function LoaderSpinner() {
  return (
    <>
      <div class="position-fixed top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center bg-white bg-opacity-75">
        <CSpinner color="primary" variant="grow" />
      </div>

    </>
  );
}
