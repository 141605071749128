import axios from "axios";
import { BACKEND_URL } from "../../../environment";
import * as Auth from "../Constants/Auth-Constants";



export const LoginAction = (params, id) => async (dispatch) => {

  if (id == 0) {
    try {
      dispatch({
        type: Auth.LOGIN_REQUEST,
      });

      const { data } = await axios.post(`${BACKEND_URL}login/`, params);

      dispatch({
        type: Auth.LOGIN_SUCCESS,
        payload: data,
      });

      localStorage.setItem("userData", JSON.stringify(data));
    } catch (error) {
      dispatch({
        type: Auth.LOGIN_FAIL,
        payload: error.response.data.message
          ? error.response.data.message
          : "something went wrong",
      });
    }
  } else if (id == 1) {
    try {
      dispatch({
        type: Auth.LOGIN_REQUEST,
      });

      const { data } = await axios.post(`${BACKEND_URL}google-authentication/`, params);

      dispatch({
        type: Auth.LOGIN_SUCCESS,
        payload: data,
      });
      localStorage.setItem("userData", JSON.stringify(data));
      return true;
    } catch (error) {
      dispatch({
        type: Auth.LOGIN_FAIL,
        payload:
          error?.response && error.response.data.message?.email?.[0]
            ? error.response && error.response.data.message?.email?.[0]
            : error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
      });
    }
  }
};



// export const LoginAction = (params) => async (dispatch) => {
//   try {
//     dispatch({
//       type: Auth.LOGIN_REQUEST,
//     });

//     const { data } = await axios.post(`${BACKEND_URL}login/`, params);

//     dispatch({
//       type: Auth.LOGIN_SUCCESS,
//       payload: data,
//     });

//     localStorage.setItem("userData", JSON.stringify(data));
//   } catch (error) {
//     dispatch({
//       type: Auth.LOGIN_FAIL,
//       payload:
//         error.response && error.response.data?.message
//           ? error.response.data?.message
//           : error.message,
//     });
//   }
// };

export const RegisterAction = (params) => async (dispatch) => {
  try {
    dispatch({
      type: Auth.REGISTER_REQUEST,
    });

    const { data } = await axios.post(`${BACKEND_URL}register/`, params);
    dispatch({
      type: Auth.REGISTER_SUCCESS,
      payload: data,
    });

    return true;
  } catch (error) {
    dispatch({
      type: Auth.REGISTER_FAIL,
      payload:
        error.response && error.response.data?.error?.non_field_errors[0]
          ? error.response.data?.error?.non_field_errors[0]
          : error.message,
    });
  }
};

export const VerifyEmailAction = (decodeId, token) => async (dispatch) => {
  try {
    dispatch({
      type: Auth.VERIFY_EMAIL_REQUEST,
    });

    const { data } = await axios.get(
      `${BACKEND_URL}verify-email/${decodeId}/${token}`
    );

    dispatch({
      type: Auth.VERIFY_EMAIL_SUCCESS,
      payload: data,
    });

    return true;
  } catch (error) {
    dispatch({
      type: Auth.VERIFY_EMAIL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const ForgotPasswordAction = (params) => async (dispatch) => {
  try {
    dispatch({
      type: Auth.FORGOT_PASSWORD_REQUEST,
    });

    const { data } = await axios.post(`${BACKEND_URL}forgot-password/`, params);

    dispatch({
      type: Auth.FORGOT_PASSWORD_SUCCESS,
      payload: data,
    });

    return true;
  } catch (error) {
    dispatch({
      type: Auth.FORGOT_PASSWORD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};


export const UpdatePasswordAction =
  (params, token, uid) => async (dispatch) => {
    try {
      dispatch({
        type: Auth.UPDATE_PASSWORD_REQUEST,
      });

      const { data } = await axios.put(
        `${BACKEND_URL}reset-password/${token}/${uid}/`,
        params
      );

      dispatch({
        type: Auth.UPDATE_PASSWORD_SUCCESS,
        payload: data,
      });

      return true;
    } catch (error) {
      dispatch({
        type: Auth.UPDATE_PASSWORD_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };




export const ProfileUpadteAction =
  (params, id) => async (dispatch, getState) => {
    try {
      dispatch({
        type: Auth.PROFILE_UPADTE_REQUEST,
      });

      const {
        authReducer: { userData },
      } = getState();

      const config = {
        headers: {
          "Content-type": "multipart/form-data",
          Authorization: `Bearer ${userData.token}`,
        },
      };
      
  
      const { data } = await axios.put(
        `${BACKEND_URL}user-profile/`, params, config
      );

      dispatch({
        type: Auth.PROFILE_UPADTE_SUCCESS,
        payload: data,
      });
      return true;
    } catch (error) {
      dispatch({
        type: Auth.PROFILE_UPADTE_FAIL,
        payload:
          error?.response && error.response.data
            ? error.response && error.response.data
            : error.response && error.response.data
              ? error.response.data.message
              : error.message,
      });
    }
  };



export const ProfileDetailsGetAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({
        type: Auth.PROFILE_DETAILS_GET_REQUEST,
      });

      const {
        authReducer: { userData },
      } = getState();
      console.log(userData)
      const config = {
        headers: {
          "Content-type": "multipart/form-data",
          Authorization: `Bearer ${userData.token}`,
        },
      };

      const { data } = await axios.get(
        `${BACKEND_URL}user-info/${id}`, config
      );
      dispatch({
        type: Auth.PROFILE_DETAILS_GET_SUCCESS,
        payload: data,
      });
      return true;
    } catch (error) {
      dispatch({
        type: Auth.PROFILE_DETAILS_GET_FAIL,
        payload:
          error?.response && error.response.data
            ? error.response && error.response.data
            : error.response && error.response.data
              ? error.response.data.message
              : error.message,
      });
    }
  };





export const LogoutAction = () => (dispatch) => {
  localStorage.removeItem("userData");
  localStorage.removeItem("Subscription");

  dispatch({ type: Auth.USER_LOGOUT });
};
