export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";

export const UPDATE_PASSWORD_REQUEST = "UPDATE_PASSWORD_REQUEST";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_FAIL = "UPDATE_PASSWORD_FAIL";


export const VERIFY_EMAIL_REQUEST = "VERIFY_EMAIL_REQUEST";
export const VERIFY_EMAIL_SUCCESS = "VERIFY_EMAIL_SUCCESS";
export const VERIFY_EMAIL_FAIL = "VERIFY_EMAIL_FAIL";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL";


export const UPDATE_REGISTER_DATA_REQUEST = "UPDATE_REGISTER_DATA_REQUEST";
export const UPDATE_REGISTER_DATA_SUCCESS = "UPDATE_REGISTER_DATA_SUCCESS";
export const UPDATE_REGISTER_DATA_FAIL = "UPDATE_REGISTER_DATA_FAIL";



export const PROFILE_UPADTE_REQUEST = "PROFILE_UPADTE_REQUEST";
export const PROFILE_UPADTE_SUCCESS = "PROFILE_UPADTE_SUCCESS";
export const PROFILE_UPADTE_FAIL = "PROFILE_UPADTE_FAIL";


export const PROFILE_DETAILS_GET_REQUEST = "PROFILE_DETAILS_GET_REQUEST";
export const PROFILE_DETAILS_GET_SUCCESS = "PROFILE_DETAILS_GET_SUCCESS";
export const PROFILE_DETAILS_GET_FAIL = "PROFILE_DETAILS_GET_FAIL";


export const USER_LOGOUT = "USER_LOGOUT";


