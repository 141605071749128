import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import {
  cilLockLocked,
  cilUser,
  cibMailRu,
  cilPhone,
  cilFlagAlt,
} from "@coreui/icons";
import swal from "sweetalert";
import { useDispatch, useSelector } from "react-redux";
import { RegisterAction } from "../Redux/Action/Auth-Action";
import LoaderSpinner from "../Loader/Loader-spinner";

const Register = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { success, error, message, registerLoading } = useSelector(
    (state) => state.RegisterReducer
  );

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    username: "",
    country: "",
    email: "",
    phone: "",
    password: "",
    confirm_password: "",
  });

  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else if (!validateEmail(formData.email)) {
      swal({
        title: "Error",
        text: "Invalid email format!",
        className: "errorAlert",
        icon: "error",
        buttons: false,
        timer: 5000,
      });
      return;
    } else if (formData.password !== formData.confirm_password) {
      swal({
        title: "Error",
        text: "Passwords do not match!",
        className: "errorAlert",
        icon: "error",
        buttons: false,
        timer: 5000,
      });
      return;
    } else if (formData.password.length < 6) {
      swal({
        title: "Error",
        text: "Password must be at least 6 characters long!",
        className: "errorAlert",
        icon: "error",
        buttons: false,
        timer: 5000,
      });
      return;
    } else {
      dispatch(RegisterAction(formData));
    }
    setValidated(true);
  };

  useEffect(() => {
    if (success) {
      swal({
        title: " ",
        text: message,
        className: "successAlert",
        icon: "success",
        buttons: false,
        timer: 5000,
      });
      setFormData({
        first_name: "",
        last_name: "",
        username: "",
        country: "",
        email: "",
        phone: "",
        password: "",
        confirm_password: "",
      });
      navigate("/thank-you");
    }
    if (error) {
      swal({
        title: "Error",
        text: error,
        className: "errorAlert",
        icon: "error",
        buttons: false,
        timer: 2000,
      });
    }
  }, [success, error, message, navigate]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <>
      {loading || registerLoading ? (
        <LoaderSpinner />
      ) : (
        <div className="bg-body-tertiary min-vh-100 d-flex flex-row align-items-center">
          <CContainer>
            <CRow className="justify-content-center">
              <CCol md={8}>
                <CCardGroup>
                  <CCard className="p-4">
                    <CCardBody>
                      <CForm
                        className="row g-3 needs-validation"
                        noValidate
                        validated={validated}
                        onSubmit={handleSubmit}
                      >
                        <h1>Register</h1>
                        <p className="text-body-secondary">
                          Sign Up to your account
                        </p>
                        <CRow>
                          <CCol md={6}>
                            <CInputGroup className="mb-3">
                              <CInputGroupText>
                                <CIcon icon={cilUser} />
                              </CInputGroupText>
                              <CFormInput
                                name="username"
                                value={formData.username}
                                onChange={handleChange}
                                type="text"
                                placeholder="Username"
                                autoComplete="username"
                                required
                              />
                            </CInputGroup>
                          </CCol>
                          <CCol md={6}>
                            <CInputGroup className="mb-3">
                              <CInputGroupText>
                                <CIcon icon={cilUser} />
                              </CInputGroupText>
                              <CFormInput
                                name="first_name"
                                value={formData.first_name}
                                onChange={handleChange}
                                type="text"
                                placeholder="First Name"
                                autoComplete="given-name"
                                required
                              />
                            </CInputGroup>
                          </CCol>
                          <CCol md={6}>
                            <CInputGroup className="mb-4">
                              <CInputGroupText>
                                <CIcon icon={cilUser} />
                              </CInputGroupText>
                              <CFormInput
                                name="last_name"
                                value={formData.last_name}
                                onChange={handleChange}
                                type="text"
                                placeholder="Last Name"
                                autoComplete="family-name"
                                required
                              />
                            </CInputGroup>
                          </CCol>
                          <CCol md={6}>
                            <CInputGroup className="mb-4">
                              <CInputGroupText>
                                <CIcon icon={cibMailRu} />
                              </CInputGroupText>
                              <CFormInput
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                type="email"
                                placeholder="Email"
                                autoComplete="email"
                                required
                              />
                            </CInputGroup>
                          </CCol>
                          <CCol md={6}>
                            <CInputGroup className="mb-4">
                              <CInputGroupText>
                                <CIcon icon={cilPhone} />
                              </CInputGroupText>
                              <CFormInput
                                name="phone"
                                value={formData.phone}
                                onChange={handleChange}
                                type="tel"
                                placeholder="Phone"
                                autoComplete="tel"
                                required
                              />
                            </CInputGroup>
                          </CCol>
                          <CCol md={6}>
                            <CInputGroup className="mb-4">
                              <CInputGroupText>
                                <CIcon icon={cilFlagAlt} />
                              </CInputGroupText>
                              <CFormInput
                                name="country"
                                value={formData.country}
                                onChange={handleChange}
                                type="text"
                                placeholder="Country"
                                autoComplete="country-name"
                                required
                              />
                            </CInputGroup>
                          </CCol>
                          <CCol md={6}>
                            <CInputGroup className="mb-4">
                              <CInputGroupText>
                                <CIcon icon={cilLockLocked} />
                              </CInputGroupText>
                              <CFormInput
                                name="password"
                                value={formData.password}
                                onChange={handleChange}
                                type="password"
                                placeholder="Password"
                                autoComplete="new-password"
                                required
                                minLength={6}
                              />
                            </CInputGroup>
                          </CCol>
                          <CCol md={6}>
                            <CInputGroup className="mb-4">
                              <CInputGroupText>
                                <CIcon icon={cilLockLocked} />
                              </CInputGroupText>
                              <CFormInput
                                name="confirm_password"
                                value={formData.confirm_password}
                                onChange={handleChange}
                                type="password"
                                placeholder="Confirm Password"
                                autoComplete="new-password"
                                required
                                minLength={6}
                              />
                            </CInputGroup>
                          </CCol>
                        </CRow>
                        <CRow>
                          <CCol xs={6}>
                            <CButton className="action-btn" color="primary" type="submit">
                              Register
                            </CButton>
                          </CCol>
                          <CCol xs={6} className="text-right">
                            <Link to="/">
                              <CButton color="link" className="px-0">
                                Already have an account? Sign In
                              </CButton>
                            </Link>
                          </CCol>
                        </CRow>
                      </CForm>
                    </CCardBody>
                  </CCard>
                </CCardGroup>
              </CCol>
            </CRow>
          </CContainer>
        </div>
      )}
    </>
  );
};

export default Register;

