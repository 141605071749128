import React, { useEffect, useState } from "react";
import { UpdatePasswordAction } from "../Redux/Action/Auth-Action";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilLockLocked } from "@coreui/icons";
import LoaderSpinner from "../Loader/Loader-spinner";

const ResetPsw = () => {
  const { token, uid } = useParams();
  const { success, error, passwordloading } = useSelector((state) => state.UpdatePasswordreducer);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [newpassword, setNewpassword] = useState("");
  const [cnewpassword, setCnewpassword] = useState("");
  const [validated, setValidated] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else if (newpassword !== cnewpassword) {
      swal({
        title: "Error",
        text: "Passwords do not match!",
        className: "errorAlert",
        icon: "error",
        buttons: false,
        timer: 5000,
      });
      return;
    } else if (newpassword.length < 6) {
      swal({
        title: "Error",
        text: "Password must be at least 6 characters long!",
        className: "errorAlert",
        icon: "error",
        buttons: false,
        timer: 5000,
      });
      return;
    } else {
      const formData = new FormData();
      formData.append("password", newpassword);
      formData.append("confirm_password", cnewpassword);
      dispatch(UpdatePasswordAction(formData, token, uid));
    }
    setValidated(true);
  };

  useEffect(() => {
    if (success) {
      swal({
        title: "",
        text: "Password Reset Successfully",
        className: "successAlert",
        icon: "success",
        buttons: false,
        timer: 5000,
      });
      navigate("/home");
    }
    if (error) {
      swal({
        title: "Error",
        text: error,
        className: "errorAlert",
        icon: "error",
        buttons: false,
        timer: 5000,
      });
    }
  }, [success, error, navigate]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);


  return (
    <>

      {loading || passwordloading ? (
        <LoaderSpinner />
      ) : (
        <div className="bg-body-tertiary min-vh-100 d-flex flex-row align-items-center">
          <CContainer>
            <CRow className="justify-content-center">
              <CCol md={8}>
                <CCardGroup>
                  <CCard className="p-4">
                    <CCardBody>
                      <CForm
                        className="row g-3 needs-validation"
                        noValidate
                        validated={validated}
                        onSubmit={handleSubmit}
                      >
                        <h1>Reset Password</h1>
                        <p className="text-body-secondary">Reset Your Password</p>
                        <CInputGroup className="mb-4">
                          <CInputGroupText>
                            <CIcon icon={cilLockLocked} />
                          </CInputGroupText>
                          <CFormInput
                            value={newpassword}
                            onChange={(e) => setNewpassword(e.target.value)}
                            type="password"
                            placeholder="New Password"
                            autoComplete="new-password"
                            required

                          />
                        </CInputGroup>
                        <CInputGroup className="mb-4">
                          <CInputGroupText>
                            <CIcon icon={cilLockLocked} />
                          </CInputGroupText>
                          <CFormInput
                            value={cnewpassword}
                            onChange={(e) => setCnewpassword(e.target.value)}
                            type="password"
                            placeholder="Confirm New Password"
                            autoComplete="new-password"
                            required

                          />
                        </CInputGroup>
                        <div>
                          <CButton
                            className="action-btn"
                            color="primary"
                            type="submit"
                          >
                            Submit
                          </CButton>
                        </div>
                      </CForm>
                    </CCardBody>
                  </CCard>
                 
                </CCardGroup>
              </CCol>
            </CRow>
          </CContainer>
        </div>

      )}

    </>

  );
};

export default ResetPsw;
