import React from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CRow,
} from "@coreui/react";
import { Link } from "react-router-dom";

const Thank_you = () => {
  return (
    <div className="bg-body-tertiary min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={8}>
            <CCardGroup>
              <CCard className="w-75 p-4">
                <CCardBody>
                  <CForm>
                    <h1>Thank You</h1>
                    <p className="text-body-secondary">
                      Thank you for registering yourself at The Media Shell. An
                      email has been sent to your registered email Id. Kindly
                      verify the email to login and start using our services
                    </p>

                    <CRow>
                      <CCol xs={6}>
                        <Link to='/'>
                        <CButton color="primary" className="px-4">
                          Login
                        </CButton>
                        </Link>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default Thank_you;
