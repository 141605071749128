import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { thunk } from "redux-thunk";
import {
  ForgotPasswordReducer,
  LoginReducer,
  VerifyEmailReducer,
  UpdatePasswordreducer,
  RegisterReducer,
  ProfileUpadteReducer,
  ProfileDetailsGetReducer,
} from "./Reducer/Auth-Reducer";

const reducer = combineReducers({
  authReducer: LoginReducer,
  RegisterReducer: RegisterReducer,
  VerifyEmailReducer: VerifyEmailReducer,
  ForgotPasswordReducer: ForgotPasswordReducer,
  UpdatePasswordreducer: UpdatePasswordreducer,
  ProfileUpadteReducer: ProfileUpadteReducer,
  ProfileDetailsGetReducer: ProfileDetailsGetReducer,
});

const userDataLocal = localStorage.getItem("userData")
  ? JSON.parse(localStorage.getItem("userData"))
  : null;

const initialState = {
  authReducer: { userData: userDataLocal },
};

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
  preloadedState: initialState,
});

export default store;
