import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import swal from "sweetalert";
import { VerifyEmailAction } from "../Redux/Action/Auth-Action";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CRow,
} from "@coreui/react";

const VerifyEmail = () => {
  const dispatch = useDispatch();
  const { decodeId, token } = useParams();
  const [render, setRerender] = useState(true);

  useEffect(() => {
    dispatch(VerifyEmailAction(decodeId, token));
    setRerender(true);
  }, []);

  const { success, error, message } = useSelector(
    (state) => state.VerifyEmailReducer
  );

  useEffect(() => {
    if (success && render) {
      swal({
        title: " Complete",
        text: message,
        className: "successAlert",
        icon: "success",
        buttons: false,
        timer: 5000,
      });
      setRerender(false);
    }

    if (error && render) {
      swal({
        title: "Error",
        text: error,
        className: "errorAlert",
        icon: "error",
        buttons: false,
        timer: 5000,
      });
      setRerender(false);
    }
  }, [success, error]);

  return (
    <div className="bg-body-tertiary min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={8}>
            <CCardGroup>
              <CCard className="w-75 p-4">
                <CCardBody>
                  <CForm>
                    <p className="text-body-secondary">
                      Welcome to The Media Shell Your email has been confirmed.
                    </p>
                    <CRow>
                      <CCol xs={6}>
                        <Link to="/">
                          <CButton color="primary" className="px-4">
                            Login
                          </CButton>
                        </Link>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default VerifyEmail;
