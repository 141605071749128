import React, { useState, useEffect,useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { GOOGLE_KEY } from "../../environment";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CFormSelect,
  CInputGroup,
  CInputGroupText,
  CRow,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilLockLocked, cilUser } from "@coreui/icons";
import { LoginAction } from "../Redux/Action/Auth-Action";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import { GoogleLogin, GoogleLogout } from "react-google-login";
import { validations } from "../../utils";
import LoaderSpinner from "../Loader/Loader-spinner";
import { gapi } from 'gapi-script';


const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { error, userData } = useSelector((state) => state.authReducer);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rerender, setRerender] = useState(false);

  const [errors, setErrors] = useState({
    email: null,
    password: null,
  });

  const [validated, setValidated] = useState(false)

  // const handleSubmit = (e) => {
  //   e.preventDefault();

  //   const form = e.currentTarget;
  //   if (form.checkValidity() === false || !validateEmail(email)) {
  //     e.preventDefault();
  //     e.stopPropagation();
  //     setErrors({
  //       ...errors,
  //       email: !validateEmail(email) ? "Please enter a valid email" : null,
  //     });
  //   } else {
  //     setValidated(true);
  //     SubmitHandler();
  //   }
  // };


  useEffect(() => {
    function initGapi() {
      gapi.client.init({
        clientId: GOOGLE_KEY, // Your client ID here
        scope: "" // Your scope here
      }).then(() => {
        // Handle the results of the initialization here
      }).catch((error) => {
        // Handle the error here
        console.error("Error initializing gapi.client:", error);
      });
    }

    function start() {
      gapi.load('client:auth2', initGapi);
    }

    start();
  }, []);

  const handleSubmit = (e) => {

    e.preventDefault();

    const form = e.currentTarget
    if (form.checkValidity() === false) {
      e.preventDefault()
      e.stopPropagation()
    }
    setValidated(true)
    SubmitHandler();

  }


  const SubmitHandler = () => {
    setRerender(true);
    const formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);
    dispatch(LoginAction(formData, "0"));
  };

  useEffect(() => {
    if (userData && rerender) {
      swal({
        title: "",
        text: "Successfully login",
        className: "successAlert",
        icon: "success",
        buttons: false,
        timer: 3000,
        open: true,
      });
      localStorage.removeItem("myurl");
      navigate("/dashboard");
    }
    if (error && rerender) {
      swal({
        title: "Error",
        text: error,
        className: "errorAlert",
        icon: "error",
        buttons: false,
        timer: 3000,
      });
      setRerender(false);
    }
  }, [dispatch, userData, error]);


  const [googleData, setGoogleData] = useState(null);

  const [visible, setVisible] = useState("none");

  const responseGoogle = (response) => {
    setGoogleData(response.profileObj);
    setVisible("Block");
  };


  const onSuccess = (res) => {
    if (res) {
      dispatch(
        LoginAction(
          {
            auth_token: res?.tokenId,
          },
          "1"
        )
      ).then(() => {
      navigate("/dashboard");
      });
    }
  };


  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (

    <>
      {loading ? (
        <LoaderSpinner />
      ) : (

        <div className="bg-body-tertiary min-vh-100 d-flex flex-row align-items-center">
          <CContainer>
            <CRow className="justify-content-center">
              <CCol md={8}>
                <CCardGroup>
                  <CCard className="p-4">
                    <CCardBody>
                      <CForm
                        className="row g-3 needs-validation"
                        noValidate
                        validated={validated}
                        onSubmit={handleSubmit}
                      >
                        <h1>Login</h1>
                        <p className="text-body-secondary">
                          Sign In to your account
                        </p>
                        <CInputGroup className="mb-3">
                          <CInputGroupText>
                            <CIcon icon={cilUser} />
                          </CInputGroupText>
                          <CFormInput
                            value={email}
                            onChange={(e) => {
                              setEmail(e.target.value);
                            }}
                            placeholder="Email"
                            autoComplete="Email"
                            required

                          />

                        </CInputGroup>
                        <CInputGroup className="mb-4">
                          <CInputGroupText>
                            <CIcon icon={cilLockLocked} />
                          </CInputGroupText>
                          <CFormInput
                            value={password}
                            onChange={(e) => {
                              setPassword(e.target.value);
                            }}
                            type="password"
                            placeholder="Password"
                            autoComplete="current-password"
                            required
                          />
                        </CInputGroup>

                        <GoogleLogin
                          clientId={GOOGLE_KEY}
                          buttonText="Sign in with Google"
                          className="google-login"
                          onSuccess={onSuccess}
                          onFailure={responseGoogle}
                          cookiePolicy={"single_host_origin"}
                        />

                        <Link to="/forgot-password">
                          <CButton color="link" className="px-0">
                            Forgot password?
                          </CButton>
                        </Link>
                        <div>
                          {/* <CButton
                            onClick={SubmitHandler}
                            color="primary"
                            className="action-btn"
                          >
                            Login
                          </CButton> */}

                          <CButton
                            className="action-btn"
                            color="primary" type="submit">
                            Submit form
                          </CButton>
                        </div>
                      </CForm>
                    </CCardBody>
                  </CCard>
                  <CCard
                    className="text-white bg-primary py-5"
                  
                  >
                    <CCardBody className="text-center">
                      <div>
                        <h2>Sign up</h2>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                          sed do eiusmod tempor incididunt ut labore et dolore magna
                          aliqua.
                        </p>
                        <Link to="/register">
                          <CButton
                            color="primary"
                            className="mt-3"
                            active
                            tabIndex={-1}
                          >
                            Register Now!
                          </CButton>
                        </Link>
                      </div>
                    </CCardBody>
                  </CCard>
                </CCardGroup>
              </CCol>
            </CRow>
          </CContainer>

        </div>

      )}
    </>
  );
};

export default Login;
