import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilLockLocked, cilUser } from "@coreui/icons";
import { ForgotPasswordAction, LoginAction } from "../Redux/Action/Auth-Action";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import LoaderSpinner from "../Loader/Loader-spinner";

const ForgotPsw = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [forgotemail, setForgotemail] = useState("");
  const [rerender, setRerender] = useState(false);

  const { message, success, error, ForgotLoading } = useSelector(
    (state) => state.ForgotPasswordReducer
  );

  const formData = new FormData();

  const [validated, setValidated] = useState(false)


  const [errors, setErrors] = useState({
    forgotemail: null,
  });

  // const handleSubmit = (e) => {

  //   e.preventDefault();

  //   const form = e.currentTarget
  //   if (form.checkValidity() === false) {
  //     e.preventDefault()
  //     e.stopPropagation()
  //   }
  //   setValidated(true)
  //   SubmitHandler();

  // }


  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false || !validateEmail(forgotemail)) {
      e.preventDefault();
      e.stopPropagation();
      setErrors({
        ...errors,
        forgotemail: !validateEmail(forgotemail) ? "Please enter a valid email" : null,
      });
    } else {
      setValidated(true);
      SubmitHandler();
    }
  };




  const SubmitHandler = () => {
    setRerender(true);
    formData.append("email", forgotemail);
    dispatch(ForgotPasswordAction(formData));
  };

  useEffect(() => {
    if (success && rerender) {
      swal({
        title: "",
        text: message,
        className: "successAlert",
        icon: "success",
        buttons: false,
        timer: 5000,
      });
      setRerender(false);
      navigate("/");
    }
    if (error && rerender) {
      swal({
        title: "Error",
        text: error,
        className: "errorAlert",
        icon: "error",
        buttons: false,
        timer: 5000,
      });
      setRerender(false);
    }
  }, [dispatch, success, error]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <>
   
      {loading || ForgotLoading ? (
        <LoaderSpinner />
      ) : (

        <div className="bg-body-tertiary min-vh-100 d-flex flex-row align-items-center">
          <CContainer>
            <CRow className="justify-content-center">
              <CCol md={8}>
                <CCardGroup>
                  <CCard className="p-4">
                    <CCardBody>
                      <CForm
                        className="row g-3 needs-validation"
                        noValidate
                        validated={validated}
                        onSubmit={handleSubmit}
                      >
                        <h1>Forgot Password</h1>
                        <p className="text-body-secondary">
                          Forgot to your password
                        </p>
                       
                        <CInputGroup className="mb-3">
                      <CInputGroupText>
                        <CIcon icon={cilUser} />
                      </CInputGroupText>
                      <CFormInput
                        value={forgotemail}
                        onChange={(e) => {
                          setForgotemail(e.target.value);
                          setErrors({
                            ...errors,
                            forgotemail: !validateEmail(e.target.value)
                              ? "Please enter a valid email"
                              : null,
                          });
                        }}
                        placeholder="Email"
                        autoComplete="Email"
                        required
                        invalid={!!errors.forgotemail}
                      />
                      <div className="invalid-feedback">
                        {errors.email}
                      </div>
                    </CInputGroup>
                        <div>
                          {/* <CButton
                            onClick={SubmitHandler}
                            color="primary"
                            className="action-btn"
                          >
                            Forgot Password
                          </CButton> */}


                          <CButton
                            className="action-btn"
                            color="primary" type="submit">
                            Forgot Password
                          </CButton>
                        </div>
                      </CForm>
                    </CCardBody>
                  </CCard>
                  <CCard
                    className="text-white bg-primary py-5"
                 
                  >
                    <CCardBody className="text-center">
                      <div>
                        <h2>Sign up</h2>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                          sed do eiusmod tempor incididunt ut labore et dolore magna
                          aliqua.
                        </p>
                        <Link to="/register">
                          <CButton
                            color="primary"
                            className="mt-3"
                            active
                            tabIndex={-1}
                          >
                            Register Now!
                          </CButton>




                        </Link>
                      </div>
                    </CCardBody>
                  </CCard>
                </CCardGroup>
              </CCol>
            </CRow>
          </CContainer>
        </div>
      )}
    </>
  );
};

export default ForgotPsw;
